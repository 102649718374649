<template>
  <div class="p-1">
    <b-row>
      <b-col
        cols="12"
        md="4"
        class="mb-md-0"
      >
        <label class="saj-text">{{ $t("Subsidiary") }}:</label>
        <b-form-select
          v-model="selectedSub"
          size="md"
          class="saj-text"
          :options="subsidiaryList"
          :disabled="roles.selectedRole !== 1"
          @change="getStaffList(), getDepartment(), resetSearch('sub')"
        />
      </b-col>
      <b-col
        v-if="!individual && roles.isHOD"
        cols="12"
        md="4"
        class="mb-md-0"
      >
        <!-- {{ roles.isHOD }}
        {{ selectedSub }} -->
        <label class="saj-text">{{ $t("Department") }}:</label>
        <v-select
          v-if="roles.isHOD"
          v-model="selectedDept"
          class="saj-text"
          label="text"
          style="background: white;"
          :placeholder="$t('Choose Department')"
          :options="departmentList"
          :value="departmentList"
          disabled
          :clearable="false"
          :reduce="val => val.value"
          @input="resetSearch('dept')"
        />
      </b-col>
      <b-col
        v-if="individual"
        cols="12"
        md="4"
        class="mb-md-0"
      >
        <label class="saj-text">{{ $t("Employee Id") }}:</label>
        <v-select
          v-model="employee_number"
          class="sini saj-title"
          label="empNo"
          style="background: white;"
          :placeholder="$t('Choose Employee')"
          :options="nameList"
          :value="nameList"
          :disabled="selectedSub === null && !isLoading"
          :clearable="false"
          :loading="isLoading"
          @input="selectedName(), resetSearch()"
        />
      </b-col>
      <!-- {{ employee_number.gredSet }} -->
      <b-col
        v-if="individual"
        cols="12"
        md="4"
        class="mb-md-0 pt-2"
      >
        <b-form-input
          v-model="employee_number.text"
          :placeholder="$t('Employee Name')"
          class="saj-text"
          size="md"
          style="margin-top:2px"
          disabled
        />
      </b-col>
      <!-- <b-col
        v-if="individual"
        cols="12"
        md="4"
        class="mb-md-0 pt-1"
      >
        <b-form-input
          v-model="employee_number.gredSet"
          placeholder="Grade Set"
          class="saj-text mt-1"
          disabled
        />
      </b-col> -->
      <b-col
        cols="12"
        md="4"
        class="mb-md-0"
      >
        <label class="saj-text">{{ $t("Grade Set") }}:</label>
        <b-form-select
          v-if="!individual"
          v-model="selectedGradeSet"
          size="md"
          class="saj-text"
          :options="gradeList"
          :disabled="selectedSub === null"
          @change="resetSearch()"
        />
        <b-form-select
          v-if="individual"
          v-model="employee_number.gredSet"
          size="md"
          class="saj-text"
          :options="gradeList"
          disabled
        />

      </b-col>

      <b-col
        cols="2"
        class="mt-2"
      >
        <b-button
          variant="primary"
          class=""
          block
          style="margin-top: 3px"
          :disabled="selectedGradeSet === null && employee_number.gredSet === null"
          @click="getAllCompetencyCoreBySubsidiary(), getAllBehaviorListeBySubsidiary()"
        >

          <span class="saj-button d-md-none d-lg-block"> {{ $t("Search") }} </span>
        </b-button>
      </b-col>
      <b-col
        cols="2"
        class="mt-2"
      >
        <b-button
          variant="primary"
          class=""
          block
          style="margin-top: 3px"
          @click="clearSearch()"
        >
          <span class="saj-button d-md-none d-lg-block"> {{ $t("Clear") }}</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BFormInput,

} from "bootstrap-vue"
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormSelect,
    BFormInput,
    vSelect,
  },
  props: {
    group: {
      type: Boolean,
      default: true,
    },
    individual: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isGroup: true,
      isIndividual: false,
      employee_number: {
        value: null,
        empNo: `${this.$i18n.t('Choose Employee ID')}`,
        text: null,
        gredSet: null,
      },
      showDetails: false,
      selectedCore: [],
      selectedBehav: [],
      status: 'not_accepted',
      subsidiaryID: '',
      departmentID: '',
      AllSubScores: [],
      AllBehaviorList: [],
      ShowCore: false,
      ShowBehavior: false,
      selectedSub: null,
      selectedDept: null,
      selectedGradeSet: null,
      gradeList: [{ text: `${this.$i18n.t('Choose Grade Set')}`, value: null, disabled: true }],
      subsidiaryList: [{ text: `${this.$i18n.t('Choose Subsidiary')}`, value: null, disabled: true }],
      departmentList: [],
      // departmentList: [{ text: `${this.$i18n.t('Choose Department')}`, value: null, disabled: true }],
      nameList: [{ text: `${this.$i18n.t('Choose Employee')}`, value: null, disabled: true }],
    }
  },
  computed: {
    ...mapGetters(['roles']),
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      this.gradeList = this.gradeList.map(x => {
        if (x.value === null){
          return {
            ...x,
            text: this.$t('Choose Grade Set'),
          }
        }
        return x
      })
      // this.departmentList = this.departmentList.map(x => {
      //   if (x.value === null){
      //     return {
      //       ...x,
      //       // text: this.$t('Choose Department'),
      //     }
      //   }
      //   return x
      // })
      this.subsidiaryList = this.subsidiaryList.map(x => {
        if (x.value === null){
          return {
            ...x,
            text: this.$t('Choose Subsidiary'),
          }
        }
        return x
      })
    },
  },
  mounted() {
    if (this.roles.selectedRole !== 1){
      this.getCurrentUser()
    }
    this.getGradeSet()
    this.getSubsidiaryList()
    // setTimeout(() => {
    //   this.getStaffList()
    // }, 300)
    // // console.log("group", this.group)
  },
  methods: {
    getGradeSet(){
      this.$axios.get(`${this.$baseUrl}/gradeSet/get_all`).then(res => {
        const grade = res.data.data

        grade.forEach(data => {
          this.gradeList.push({
            text: data.name,
            value: data.id,
          })
        })
      })
    },
    getSubsidiaryList(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(res => {
        const sub = res.data.data.subsidiaries

        sub.forEach(data => {
          this.subsidiaryList.push({
            text: data.subsidiary_name,
            value: data.id,
          })
        })
      })
    },
    getStaffList() {
      this.nameList = []
      this.isLoading = true

      const params = new URLSearchParams()

      params.append('subsidiary_id', this.selectedSub)
      if (this.roles.selectedRole === 5){ // hod
        params.append('department_id', this.selectedDept)
      }
      // console.log('sub', this.selectedSub)

      this.$axios.get(`${this.$baseUrl}/employees/getAllEmployeesNameWithoutPerformance`, { params }).then(response => {
        const allName = response.data.data
        // console.log('all name', response)

        this.nameList = allName.map(item => ({
          value: item.employee_id,
          empNo: item.employee_number,
          text: item.full_name,
          gredSet: item.grade.grade_set_id,
        }))

        this.isLoading = false
      })
    },
    selectedName(){
      // console.log('selected emp', this.employee_number)
      if (this.employee_number === null){
        this.employee_number = {
          value: null,
          empNo: `${this.$i18n.t('Choose Employee ID')}`,
          text: null,
          gredSet: null,
        }
      }
    },
    getCurrentUser(){
      this.$axios.get(`${this.$baseUrl}/users/current_user`).then(response => {
        // console.log('Current User', response)
        this.selectedSub = response.data.data.employee.business_unit
        this.selectedDept = response.data.data.employee.department_id
        this.getDepartment()

        if (this.roles.isHOD || this.roles.isHR){
          this.getStaffList()
        }
      })
    },
    getDepartment(){
      // console.log('masuk dept')
      this.$axios.get(`${this.$baseUrl}/departments/get_departments_by_subsidiary?subsidiary_id=${this.selectedSub}`).then(response => {
        // console.log("ada list department", response)
        this.allDept = response.data.data
        if (response.data.success){
          // this.departmentList = [{ text: `${this.$t('Choose Department')}`, value: null, disabled: true }]

          this.allDept.forEach(b => {
            this.departmentList.push({
              value: b.id,
              text: b.name,
            })
          })
        } else {
          this.departmentList = [{ text: `${this.$t('No Department')}`, value: null, disabled: true }]
        }
      })
    },
    clearSearch(){
      this.$emit('clear-showTabs')
      if (this.roles.selectedRole === 1){
        this.selectedSub = null
      }
      if (this.roles.selectedRole !== 5){
        this.selectedDept = null
        this.employee_number = null
      }

      this.selectedGradeSet = null
      this.employee_number = {
        value: null,
        empNo: `${this.$i18n.t('Choose Employee ID')}`,
        text: null,
        gredSet: null,
      }
    },
    getAllBehaviorListeBySubsidiary() {
      const params = new URLSearchParams()
      this.$emit('success-showBehavior', true)

      params.append(`subsidiary_id`, this.selectedSub)
      // if (this.roles.selectedRole === 1 || this.roles.selectedRole === 6) {
      //   params.append(`gradeset_id`, this.selectedGradeSet)
      // }

      if (this.roles.selectedRole === 1 || this.roles.selectedRole === 6) {
        if (this.individual){
          params.append(`employee_id`, this.employee_number.value)
          params.append(`gradeset_id`, this.employee_number.gredSet)
        } else {
          params.append(`gradeset_id`, this.selectedGradeSet)
        }
      }

      if (this.roles.selectedRole === 5){
        if (this.individual){
          params.append(`employee_id`, this.employee_number.value)
          params.append(`gradeset_id`, this.employee_number.gredSet)
        } else {
          params.append(`department_id`, this.selectedDept)
          params.append(`gradeset_id`, this.selectedGradeSet)
        }
      }

      // params.forEach(a => {
      //   console.log(a)
      // })

      this.$axios.get(`${this.$baseUrl}/competency_behaviour/get_behaviours_list`, { params })
        .then(response => {
          // console.log("behav", response)
          const result = response.data
          this.selectedBehav = []
          if (result.success) {
            this.AllBehaviorList = result.data
            this.$emit('success-showBehavior', false)

            this.AllBehaviorList.forEach(behav => {
              if (behav.status === 1){
                this.selectedBehav.push(behav.behaviour_id)
              }
            })

            // console.log('beh', result.data[0].change_acces === 0)
            this.$emit('emit-selectedBehav', this.selectedBehav)
            this.$emit('success-showTabs')
            this.$emit('success-AllBehaviorList', this.AllBehaviorList)
            this.$emit('save-behavior', result.data[0].change_acces === 0)
          }
        })
        .catch(() => {
          // console.log(error)
          this.ShowBehavior = false
        })
    },
    getAllCompetencyCoreBySubsidiary() {
      // console.log('1')
      const params = new URLSearchParams()
      this.$emit('success-showCore', true)

      params.append(`subsidiary_id`, this.selectedSub)

      if (this.roles.selectedRole === 1 || this.roles.selectedRole === 6) {
        if (this.individual){
          params.append(`employee_id`, this.employee_number.value)
          params.append(`gradeset_id`, this.employee_number.gredSet)
        } else {
          params.append(`gradeset_id`, this.selectedGradeSet)
        }
      }

      if (this.roles.selectedRole === 5){
        if (this.individual){
          params.append(`employee_id`, this.employee_number.value)
          params.append(`gradeset_id`, this.employee_number.gredSet)
        } else {
          params.append(`department_id`, this.selectedDept)
          params.append(`gradeset_id`, this.selectedGradeSet)
        }
      }

      // params.forEach(a => {
      //   console.log("apa dia append", a)
      // })

      this.$emit('emit-selectedSub', this.selectedSub)
      this.$emit('emit-selectedGradeSet', this.selectedGradeSet)
      this.$emit('emit-selectedDept', this.selectedDept)
      this.$emit('emit-selectedGradeSetIndividual', this.employee_number.gredSet)
      this.$emit('emit-selectedEmpId', this.employee_number.value)

      this.$axios.get(`${this.$baseUrl}/competency_subcore/get_subcores_list`, { params })
        .then(response => {
          const result = response.data
          if (result.success) {
            this.AllSubScores = result.data
            this.$emit('success-showCore', false)

            this.selectedCore = []
            // console.log("core", this.AllSubScores[0].change_access)
            this.AllSubScores.forEach(core => {
              core.subcore.forEach(subScore => {
                if (subScore.status === 1){
                  this.selectedCore.push(subScore.subcore_id)
                }
              })
            })
            this.$emit('emit-selectedCore', this.selectedCore)
            this.$emit('success-showTabs')
            this.$emit('success-AllSubScores', this.AllSubScores)
            this.$emit('save-core', this.AllSubScores[0].change_access === 0)
          }
        })
        .catch(() => {
          this.ShowCore = false
        })
    },
    resetSearch(type){
      switch (type){
        case 'sub':
          this.selectedDept = null
          if (!this.roles.isHOD){
            this.selectedGradeSet = null
          } else {
            this.employee_number.gredSet = null
          }
          break
        case 'dept':
          if (!this.roles.isHOD){
            this.selectedGradeSet = null
          } else {
            this.employee_number.gredSet = null
          }
          break
        default:
      }
      this.$emit("clear-showTabs")
    },
  },
}
</script>

<style scoped>

.sini{
    display: block;
    width: 100%;
    height: 3.714rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
}
</style>
