<template>
  <div class="">
    <b-row>
      <b-col md="12">
        <b-card
          class=""
          style="
            "
        >
          <!-- {{ saveCore }} and {{ saveBehavior }} and {{ roles }} -->
          <div class="">
            <b-col
              cols="12"
              class="d-flex justify-content-end"
            >
              <!-- <b-button
                v-model="buttonTab"
                variant="primary"
                class=""
                style=""
                :style="! roles.isHOD ? `display: none;` : ``"
                @click="isIndividual = !isIndividual, isGroup = !isGroup, clearSearch()"
              > -->
              <b-button
                v-model="buttonTab"
                variant="primary"
                class=""
                style=""
                @click="isIndividual = !isIndividual, isGroup = !isGroup, clearSearch()"
              >
                <span class="saj-button d-md-none d-lg-block"> {{ isGroup === true ? $t("Set Individually") : $t("Set Group") }} </span>
              </b-button>
            </b-col>
          </div>
          <div class="saj-title ml-1">
            {{ isGroup === true ? $t("Set by group") : $t("Set by individual") }}:
          </div>
          <div
            v-if="isGroup"
            class="row align-items-end px-1 mb-4"
          >
            <!-- if admin -->
            <template>
              <category
                :group="isGroup"
                @success-showTabs="showTabs = true"
                @clear-showTabs="showTabs = false"
                @success-showCore="val => showCore = val"
                @success-AllSubScores="val => allSubScores = val"
                @success-showBehavior="val => showBehavior = val"
                @success-AllBehaviorList="val => allBehaviorList = val"
                @emit-selectedSub="val => selectedSub = val"
                @emit-selectedGradeSet="val => selectedGradeSet = val"
                @emit-selectedDept="val => selectedDept = val"
                @emit-selectedCore="val => selectedCore = val"
                @emit-selectedBehav="val => selectedBehav = val"
                @save-core="val => saveCore = val"
                @save-behavior="val => saveBehavior = val"
              />
            </template>
          </div>
          <div
            v-if="isIndividual"
            class="row align-items-end px-1 mb-4"
          >
            <template>
              <category
                :individual="isIndividual"
                @success-showTabs="showTabs = true"
                @clear-showTabs="showTabs = false"
                @success-showCore="val => showCore = val"
                @success-AllSubScores="val => allSubScores = val"
                @success-showBehavior="val => showBehavior = val"
                @success-AllBehaviorList="val => allBehaviorList = val"
                @emit-selectedSub="val => selectedSub = val"
                @emit-selectedDept="val => selectedDept = val"
                @emit-selectedCore="val => selectedCore = val"
                @emit-selectedBehav="val => selectedBehav = val"
                @emit-selectedGradeSetIndividual="val => employee_number.gredSet = val"
                @emit-selectedEmpId="val => selectedEmp = val"
                @save-core="val => saveCore = val"
                @save-behavior="val => saveBehavior = val"
              />
            </template>
          </div>
          <b-tabs
            v-if="showTabs"
            nav-class="saj-title m-0 mt-4"
            style="
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0.1em;
              "
          >
            <!-- kompetensi teras -->
            <b-tab
              :title="$t('Core Competencies')"
              active
            >

              <div
                v-if="roles.isAdmin ? true : saveCore"
                class="d-flex justify-content-end"
                style=""
              >
                <button
                  class="btn m-1 saj-button"
                  aria-label="submit modal"
                  style="
                color: white;
                background: #0b103c !important;
                border-color: #0b103c !important;
              "
                  @click="updateCore()"
                >
                  {{ $t('Save') }}
                </button>
              </div>
              <!-- Core -->
              <!-- HR -->
              <b-overlay
                :show="showCore"
                rounded="sm"
              >

                <template #overlay>
                  <div class="d-flex flex-column align-items-center justify-content-center p-2">
                    <b-spinner
                      variant="primary"
                      label="Spinning"
                    />
                    <p class="mt-1 pb-2">
                      {{ $t('Fetching Data') }}...
                    </p>
                  </div>

                </template>
                <div :key="refreshCore">
                  <div
                    v-for="(core) in allSubScores"
                    :key="core.id"

                    class=""
                  >
                    <b-col class="mt-1">
                      <b-card
                        class="mb-1"
                        style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                      >
                        <b-row>
                          <b-card-body class="">
                            <div
                              class=""
                            >
                              <b-row>
                                <b-col
                                  cols="4"
                                  class="justify-content-start"
                                  style="
                          "
                                >
                                  <div class="justify-content-start">
                                    <h3
                                      class="saj-title"
                                      style="
                              "
                                    >
                                      {{ $t('Core') }} {{ core.id }}: {{ isEnglish ? core.name_bi : core.name }}
                                    </h3>
                                  </div>
                                </b-col>
                                <b-col
                                  cols="8"
                                  class="justify-content-start"
                                  style="
                          "
                                >
                                  <div class="justify-content-start">
                                    <h3
                                      class="saj-text"
                                      style="
                              "
                                    >
                                      {{ isEnglish ? core.description_bi : core.description }}
                                    </h3>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row>
                                <div class="justify-content-start ml-1 mt-1">
                                  <h3
                                    class="saj-text"
                                    style="
                                "
                                  >
                                    {{ $t('Choose two') }} (2) {{ $t('of the following items') }}:
                                  </h3>
                                </div>
                              </b-row>
                              <b-row>
                                <div>
                                  <b-row>
                                    <b-card-body class="saj-text">
                                      <div
                                        v-for="(sub1) in core.subcore"
                                        :key="sub1.subcore_id"
                                        class=""
                                      >
                                        <b-row>
                                          <b-col
                                            cols="1"
                                            class="saj-text justify-content-start"
                                            style="
                                    "
                                          >
                                            <div class="d-flex justify-content-center">
                                              <b-form-checkbox
                                                v-model="selectedCore"
                                                label="id of product"
                                                type="checkbox"
                                                :value="sub1.subcore_id"
                                                checked
                                              />

                                            </div>
                                          </b-col>
                                          <b-col
                                            cols="3"
                                            class="justify-content-start"
                                            style="
                                    "
                                          >
                                            <div class="">
                                              <h3
                                                class="saj-text"
                                                style="
                                            color: #464e5f;
                                        "
                                              >
                                                <!-- status {{ sub1.status }} -->
                                                {{ isEnglish ? sub1.name_bi : sub1.name }}
                                              </h3>
                                            </div>
                                          </b-col>
                                          <b-col
                                            cols="8"
                                            class=""
                                            style="
                                    "
                                          >
                                            <div
                                              class=""
                                            >
                                              <h3
                                                class="saj-text"
                                                style="
                                            color: #464e5f;
                                        "
                                              >
                                                {{ isEnglish ? sub1.description_bi : sub1.description }}
                                              </h3>
                                            </div>
                                          </b-col>
                                        </b-row>
                                      </div>
                                    </b-card-body>
                                  </b-row>
                                </div>
                              </b-row>
                            </div>
                          </b-card-body>
                        </b-row>
                      </b-card>
                    </b-col>
                  </div>
                </div>
                <!-- HR Ends-->
              </b-overlay>
            </b-tab>
            <!-- Behavior -->
            <b-tab :title="$t('Behaviourial Competencies')">
              <b-col class="mt-1">
                <div
                  v-if="roles.isAdmin ? true : saveBehavior"
                  class="d-flex justify-content-end"
                  style=""
                >
                  <button
                    class="btn m-1 saj-button"
                    aria-label="submit modal"
                    style="
                            color: white;
                            background: #0b103c !important;
                            border-color: #0b103c !important;
                          "
                    @click="updateBehavior()"
                  >
                    {{ $t('Save') }}
                  </button>
                </div>
                <!-- Behavior HR -->
                <b-overlay
                  :show="showBehavior"
                  rounded="sm"
                >

                  <template #overlay>
                    <div class="d-flex flex-column align-items-center justify-content-center p-2">
                      <b-spinner
                        variant="primary"
                        label="Spinning"
                      />
                      <p class="mt-1 pb-2">
                        {{ $t('Fetching Data') }}...
                      </p>
                    </div>

                  </template>
                  <b-card
                    v-show="!showBehavior"
                    class="mt-1"
                    style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                  >
                    <b-row>
                      <div class="d-flex align-items-start ml-1">
                        <h3
                          class="saj-text"
                          style="
                            "
                        >
                          {{ $t('Select the following five') }} (5) {{ $t('items') }}:
                          <!-- {{ selected }} -->
                        </h3>
                      </div>
                    </b-row>
                    <b-row :key="refreshBehav">
                      <b-card-body class="">
                        <div
                          v-for="(item, index) in allBehaviorList"
                          :key="index"
                          class="
                          mt-1
                              saj-text
                              d-flex
                              justify-content-between
                              align-items-start
                            "
                        >
                          <b-row
                            style="width: 100%"
                            class=""
                          >
                            <b-col
                              cols="1"
                              class="saj-text justify-content-start"
                              style=""
                            >
                              <div class="saj-text d-flex align-items-center">
                                <b-form-checkbox
                                  v-model="selectedBehav"
                                  name="checkbox-2"
                                  label="id of product"
                                  type="checkbox"
                                  :value="item.behaviour_id"
                                />
                              </div>
                            </b-col>
                            <b-col
                              cols="11"
                              class=""
                            >
                              <b-row
                                class="saj-text justify-content-start"
                                style=""
                              >
                                <div class="d-flex">
                                  <h3
                                    class="saj-title font-weight-bolder"
                                    style="
                                    "
                                    @click="showDetails = !showDetails"
                                  >
                                    <!-- status {{ item.status }} -->
                                    {{ isEnglish ? item.name_bi : item.name }}
                                  </h3>
                                  <feather-icon
                                    :icon="
                                      showDetails !== true
                                        ? 'ChevronDownIcon'
                                        : 'ChevronUpIcon'
                                    "
                                    size="26"
                                    class="ml-1"
                                    @click="showDetails = !showDetails"
                                  />
                                </div>

                              </b-row>
                              <b-row
                                class=" saj-text justify-content-start ml-1"
                                style=""
                              >

                                <!-- sub -->
                                <div
                                  v-for="(
                                    sub, i
                                  ) in item.subbehaviour"
                                  v-show="showDetails"
                                  :key="i"
                                  class=""
                                >
                                  <h3
                                    class="saj-text"
                                    style="
                                      align-items:left; "
                                  >
                                    <ul class="">
                                      <li
                                        class=""
                                        style="
                                          align-items: left;
                                        "
                                      >
                                        {{ isEnglish ? sub.name_bi : sub.name }}
                                      </li>
                                    </ul>
                                  </h3>
                                </div>
                              </b-row>
                            </b-col>

                          </b-row>
                        </div>
                      </b-card-body>
                    </b-row>
                  </b-card>
                </b-overlay>
              </b-col>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTabs,
  BTab,
  BFormCheckbox,
  BOverlay,
  BSpinner,
  BButton,
  // BFormSelect,
  // BFormInput,

} from "bootstrap-vue"
// import axios from "axios"
import SAJToast from '@/component/saj-toastification.vue'
import { mapGetters } from 'vuex'
// import vSelect from 'vue-select/'
import category from '@/pages/hod/theme-category.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTabs,
    BTab,
    BFormCheckbox,
    BOverlay,
    BSpinner,
    BButton,
    // BFormSelect,
    // BFormInput,
    // vSelect,
    category,
  },
  data() {
    return {
      selectedEmp: null,
      saveCore: null,
      saveBehavior: null,
      buttonTab: null,
      isGroup: true,
      isIndividual: false,
      employee_number: {
        value: null,
        empNo: 'Choose Employee ID',
        text: null,
      },
      refreshCore: 0,
      refreshBehav: 0,
      showDetails: false,
      selectedCore: [],
      selectedBehav: [],
      status: 'not_accepted',
      subsidiaryID: '',
      departmentID: '',
      allSubScores: [],
      allBehaviorList: [],
      showCore: false,
      showBehavior: false,
      selectedSub: null,
      selectedDept: null,
      selectedGradeSet: null,
      gradeList: [{ text: `${this.$i18n.t('Choose Grade Set')}`, value: null, disabled: true }],
      subsidiaryList: [{ text: `${this.$i18n.t('Choose Subsidiary')}`, value: null, disabled: true }],
      departmentList: [{ text: `${this.$i18n.t('Choose Department')}`, value: null, disabled: true }],
      nameList: [{ text: `${this.$i18n.t('Choose Employee')}`, value: null, disabled: true }],
      showTabs: false,
    }
  },

  computed: {
    ...mapGetters(['roles']),
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },
  mounted() {
  },
  methods: {
    forceRerenderCore(){
      this.refreshCore += 1
    },
    getSubsidiaryList(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(res => {
        const sub = res.data.data.subsidiaries

        sub.forEach(data => {
          this.subsidiaryList.push({
            text: data.subsidiary_name,
            value: data.id,
          })
        })
      })
    },
    getStaffList() {
      // console.log('masuk member name', id)
      // const params = new FormData()
      // params.append("subsidiary_id", this.id)
      this.nameList = []

      const params = new URLSearchParams()

      params.append('subsidiary_id', this.selectedSub)
      // console.log('sub', this.selectedSub)

      this.$axios.get(`${this.$baseUrl}/employees/getAllEmployeesNameWithoutPerformance`, { params }).then(response => {
        const allName = response.data.data
        // console.log('all name', response)

        this.nameList = allName.map(item => ({
          value: item.employee_id,
          empNo: item.employee_number,
          text: item.full_name,
        }))
      })
    },
    selectedName(){
      console.log('selected emp', this.employee_number)
      if (this.employee_number === null){
        this.employee_number = {
          value: null,
          empNo: 'Choose Employee ID',
          text: null,
        }
      }
    },
    getCurrentUser(){
      this.$axios.get(`${this.$baseUrl}/users/current_user`).then(response => {
        // console.log('Current User', response)
        this.selectedSub = response.data.data.employee.business_unit
        this.selectedDept = response.data.data.employee.department_id
        this.getDepartment()
      })
    },
    getDepartment(){
      this.$axios.get(`${this.$baseUrl}/departments/get_departments_by_subsidiary?subsidiary_id=${this.selectedSub}`).then(response => {
        // console.log("ada list department", response)
        this.allDept = response.data.data
        if (response.data.success){
          this.departmentList = [{ text: `${this.$t('Choose Department')}`, value: null, disabled: true }]

          this.allDept.forEach(b => {
            this.departmentList.push({
              value: b.id,
              text: b.name,
            })
          })
        } else {
          this.departmentList = [{ text: `${this.$t('No Department')}`, value: null, disabled: true }]
        }
      })
    },
    clearSearch(){
      this.buttonTab = null
      this.showTabs = false
      if (this.roles.selectedRole === 1){
        this.selectedSub = null
      }
      if (this.roles.selectedRole !== 5){
        this.selectedDept = null
      }

      this.selectedGradeSet = null
      // this.selectedDept = null
    },
    forceRerenderBehav(){
      this.refreshBehav += 1
    },
    updateCore(){
      const data = new FormData()

      data.append("subsidiary_id", this.selectedSub)
      // data.append("gradeset_id", this.selectedGradeSet)
      if (this.isIndividual){
        data.append("gradeset_id", this.employee_number.gredSet)
        data.append("employee_id", this.selectedEmp)
      } else {
        data.append("gradeset_id", this.selectedGradeSet)
      }
      if (this.roles.selectedRole === 4){
        if (this.isGroup){
          data.append(`department_id`, this.selectedDept)
        }
      }
      this.selectedCore.forEach(k => {
        data.append("subcore_id[]", k)
      })

      data.append('change_access', 1)
      // data.forEach(a => {
      //   console.log("selected core", a)
      // })

      this.$axios.post(`${this.$baseUrl}/competency_subcore/update_subcores`, data).then(() => {
        // this.getAllCompetencyCoreBySubsidiary()
        this.forceRerenderCore()
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('You have successfully updated Subcore')}.`,
              icon: 'CheckCircleIcon',
              variant: 'success',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
      }).catch(() => {
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Please choose 2 subcore from each core')}.`,
              icon: 'XIcon',
              iconBg: '#e80202',
              variant: 'danger',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'error',
          },
        )
      })
    },
    updateBehavior(){
      const data = new FormData()

      data.append("subsidiary_id", this.selectedSub)
      // data.append("gradeset_id", this.selectedGradeSet)
      if (this.isIndividual){
        data.append("gradeset_id", this.employee_number.gredSet)
        data.append("employee_id", this.selectedEmp)
      } else {
        data.append("gradeset_id", this.selectedGradeSet)
      }
      if (this.roles.selectedRole === 4){
        data.append(`department_id`, this.selectedDept)
      }

      this.selectedBehav.forEach(k => {
        data.append(`behaviour_id[]`, k)
      })

      data.append('change_access', 1)

      this.$axios.post(`${this.$baseUrl}/competency_behaviour/update_behaviours`, data).then(() => {
        // this.getAllBehaviorListeBySubsidiary()
        this.forceRerenderBehav()
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('You have successfully updated Behaviour')}.`,
              icon: 'CheckCircleIcon',
              variant: 'success',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
      }).catch(() => {
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Please choose 5 behaviour')}.`,
              icon: 'XIcon',
              iconBg: '#e80202',
              variant: 'danger',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'error',
          },
        )
      })
    },
  },
}
</script>

<style scoped>
.sini{
    display: block;
    width: 100%;
    height: 2.714rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
}
</style>
